<template>
  <v-container>
      <v-row>
          <v-col class="primary--text text-h6">
              Confirma los datos
          </v-col>
      </v-row>
      <v-row class="py-0">
          <v-col>
              <v-text-field
                disabled
                label="Numero a 10 digitos"
                class="py-0 my-0"
                v-model="numero"
              >
              </v-text-field>
          </v-col>
      </v-row>
      <v-row class="py-0">
          <v-col>
              <v-text-field
                disabled
                label="Recarga"
                class="py-0 my-0"
                v-model="recarga"
              >
              </v-text-field>
          </v-col>
      </v-row>
      <v-row class="py-0">
          <v-col>
              <v-text-field
                disabled
                label="Metodo de pago"
                class="py-0 my-0"
                v-model="metodo"
              >
              </v-text-field>
          </v-col>
      </v-row>
      <v-row>
          <v-col>
              <v-btn
                block
                color="primary"
                @click="siguiente"
                :disabled="disableBtn"
              >Siguiente</v-btn>
          </v-col>
      </v-row>
      <v-snackbar
          rounded="pill"
          v-model="snackbar"
          min-width="0px"
          timeout="2000"
          >
          {{mensajeSnack}}
      </v-snackbar>
  </v-container>
</template>

<script>

export default {
    data(){
        return{
            numero: '',
            recarga: '',
            metodo: '',
            saldo: -1,
            snackbar: false,
            mensajeSnack: '',
            disableBtn: false,
            pago:{
                Id: '',
                Ref: ''
            },
            idOrden: ''
        }
    },
    mounted(){
        this.numero = this.$route.params.numero
        this.recarga = this.$route.params.plan.Nombre
        this.metodo = this.$route.params.metodo
    },
    created(){
        if (!this.$route.params.plan.Costo) {
            this.$router.push({name: 'Movil'})
        }
    },
    methods:{
        siguiente(){
            this.disableBtn = true
            if (this.metodo==='Tienda') {
                const uid = require('uuid');
                this.idOrden = uid.v4();
                this.hacerCargo().then(r=>{
                    this.pago.Ref = r.Ref
                    this.pago.Id = r.Id
                    this.GuardarTransaccion().then(d=>{
                        this.mensajeSnack = 'Recibo guardado en pagos'
                        this.snackbar = true
                        setTimeout(()=>{
                            this.$router.push('/Movil')
                        },2000)
                    },()=>{
                        this.mensajeSnack = 'Hubo un error al generar el recibo'
                        this.snackbar = true
                        this.disableBtn = false
                    })
                },()=>{
                    this.mensajeSnack = 'Hubo un error al generar el cargo'
                    this.snackbar = true
                    this.disableBtn = false
                })
            }else{
                const datos = {
                    numero: this.numero,
                    plan: this.$route.params.plan,
                    metodo: this.$route.params.metodo
                }
                this.$router.push({name: 'PagoTarjeta', params: datos})
            }
        },
        addTelefono: async function(){
            const body = {
                celular: this.numero,
                token: localStorage.getItem('token'),
                oferta: this.$route.params.plan.IdAltan,
            }
            var datos = {
                body: JSON.stringify(body),
                method: 'POST'
            }

            const response = await fetch('https://maifon.mx/api/addPlan.php',datos)
            const data = await response.json()
            return data
        },
        RestarSaldo: async function(){
            const body = {
                opcion: 31,
                id: localStorage.getItem('id'),
                saldo: this.saldo,
            }
            const params = {
                body: JSON.stringify(body),
                method: 'post'
            }
            const response = await fetch('https://maifon.mx/Conexion/crudCrecer.php', params)
            const data = await response.json()
            return data
        },
        GetSaldo: async function(){
            const body = {
                opcion: 30,
                id: localStorage.getItem('id'),
            }
            const params = {
                body: JSON.stringify(body),
                method: 'post'
            }
            const response = await fetch('https://maifon.mx/Conexion/crudCrecer.php', params)
            const data = await response.json()
            return data
        },
        GuardarTransaccion: async function(){
            const body = {
                opcion: 36,
                IdUser: localStorage.getItem('id'),
                Numero: this.numero,
                Plan: this.$route.params.plan.IdAltan,
                TipoTran: this.metodo,
                ValTran: this.$route.params.plan.Costo,
                Ref: this.pago.Ref,
                IdPay: this.idOrden,
            }
            const params = {
                body: JSON.stringify(body),
                method: 'post'
            }
            const response = await fetch('https://maifon.mx/Conexion/crudCrecer.php', params)
            const data = await response.json()
            return data
        },
        hacerCargo: async function(){
            const body = {
                nombre: localStorage.getItem('nombre'),
                apellido: localStorage.getItem('apellido'),
                celular: this.numero,
                email: localStorage.getItem('email'),
                amount: this.$route.params.plan.Costo,
                idAltan: this.idOrden,
                descripcion: this.$route.params.plan.Nombre,
            }
            const params = {
                body: JSON.stringify(body),
                method: 'post'
            }

            const response = await fetch('https://maifon.mx/Conexion/pagoOxxo.php', params)
            const data = await response.json()
            return data
        }
    }
}
</script>